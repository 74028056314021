body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: column;
  font-family: 'Open Sans', sans-serif;
  height: 100vh;
  justify-content: center;
  padding: 20px;
  text-align: center;
  width: 100vw;
}

h1 {
  font-size: 40px;
  font-weight: 800;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}

p {
  font-weight: 300;
  letter-spacing: 0.6px;
  margin-block-start: 0;
  margin-block-end: 0;
  font-size: 20px;
}

a,
a:active,
a:visited {
  color: rgba(0, 0, 0, 0.87);
}

@media screen and (max-width: 500px) {
  h1 {
    font-size: 28px;
  }
}
